import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  Get1010CouponModel,
  GetTypeCouponModel,
} from '@/models/Get1010Coupon.model';
import {
  get1010Coupon,
  getTypeCoupon,
} from '@/services/client/get1010CouponService';
interface Get1010CouponState {
  result: Get1010CouponModel;
  isLoading: boolean;
  resultTypeCoupon?: GetTypeCouponModel;
  isClick: boolean;
}

const initialState: Get1010CouponState = {
  result: {
    iserror: true,
    msg: 'Initial message',
    status: 500,
  },
  isLoading: false,
  isClick: false,
};

export const get1010CouponQuery = createAsyncThunk(
  'get1010Coupon/add',
  async ({ lang, rewardid }: { lang: string; rewardid: string }) => {
    const response = await get1010Coupon({ lang, rewardid });
    return response;
  },
);

export const getTypeCouponQuery = createAsyncThunk(
  'getTypeCoupon/add',
  async ({
    lang,
    campaignId,
    type,
  }: {
    lang: string;
    campaignId: string;
    type: string;
  }) => {
    const response = await getTypeCoupon({ lang, campaignId, type });
    return response;
  },
);

const get1010CouponSlice = createSlice({
  name: 'get1010Coupon',
  initialState: initialState,
  reducers: {
    clearResultGet1010: (state) => {
      state.result = {};
      state.resultTypeCoupon = undefined;
    },
    setIsClick: (state, action: { payload: boolean }) => {
      state.isClick = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get1010CouponQuery.pending, (state) => {
        state.result = {};
        state.isLoading = true;
      })
      .addCase(get1010CouponQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(get1010CouponQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
    builder
      .addCase(getTypeCouponQuery.pending, (state) => {
        state.resultTypeCoupon = undefined;
        state.isLoading = true;
      })
      .addCase(getTypeCouponQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resultTypeCoupon = action.payload;
      })
      .addCase(getTypeCouponQuery.rejected, (state) => {
        state.isLoading = false;
        state.resultTypeCoupon = undefined;
      });
  },
});

export const { clearResultGet1010, setIsClick } = get1010CouponSlice.actions;

export const get1010CouponResultsSelector = (
  store: RootState,
): Get1010CouponModel => store.get1010Coupon.result;

export const getTypeCouponResultsSelector = (
  store: RootState,
): GetTypeCouponModel | undefined => store.get1010Coupon.resultTypeCoupon;

export const isLoading1010CouponSelector = (store: RootState): boolean =>
  store.get1010Coupon.isLoading;

export const isClickGet1010couponSelector = (store: RootState): boolean =>
  store.get1010Coupon.isClick;

export default get1010CouponSlice.reducer;
