import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';

export enum CampaignPageState {
  // eslint-disable-next-line no-unused-vars
  current = 'current',
  // eslint-disable-next-line no-unused-vars
  previous = 'previous',
}

export enum CampaignMachanic {
  // eslint-disable-next-line no-unused-vars
  topspender = 0,
  // eslint-disable-next-line no-unused-vars
  loyalty = 1,
  // eslint-disable-next-line no-unused-vars
  winprizes = 2,
}

export enum CampaignCardState {
  // eslint-disable-next-line no-unused-vars
  other = 0,
  // eslint-disable-next-line no-unused-vars
  active = 1,
  // eslint-disable-next-line no-unused-vars
  rewarded = 2,
  // eslint-disable-next-line no-unused-vars
  ended = 3,
}

export interface CampaignPageResponse {
  title?: string;
  sub?: string;
  myCampaigns?: MyCampaign[];
  myEndedCampaigns?: MyCampaign[];
  myRewardedCampaigns?: MyCampaign[];
  otherCampaigns?: MyCampaign[];
  offlineMyCampaigns?: OfflineMyCampaign[];
  offlineOtherCampaigns?: OfflineMyCampaign[];
  offlineMyEndedCampaigns?: any[];
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export class CampaignPageResponseConverter {
  public static fromJson(json: any): CampaignPageResponse {
    const model: CampaignPageResponse = {
      title: json['title'],
      sub: json['sub'],
      myCampaigns: json['mycampaigns']
        ? json['mycampaigns'].map((x: any) => MyCampaignConverter.fromJson(x))
        : undefined,
      myEndedCampaigns: json['myendedcampaigns']
        ? json['myendedcampaigns'].map((x: any) =>
            MyCampaignConverter.fromJson(x),
          )
        : undefined,
      myRewardedCampaigns: json['myrewardedcampaigns']
        ? json['myrewardedcampaigns'].map((x: any) =>
            MyCampaignConverter.fromJson(x),
          )
        : undefined,
      otherCampaigns: json['otherscampaigns']
        ? json['otherscampaigns'].map((x: any) =>
            MyCampaignConverter.fromJson(x),
          )
        : undefined,
      offlineMyCampaigns: json['offlineMyCampaigns']
        ? json['offlineMyCampaigns'].map((x: any) =>
            OfflineMyCampaignConverter.fromJson(x),
          )
        : undefined,
      offlineOtherCampaigns: json['offlineOtherCampaigns']
        ? json['offlineOtherCampaigns'].map((x: any) =>
            OfflineMyCampaignConverter.fromJson(x),
          )
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface MyCampaign {
  id?: number;
  campaignId?: string;
  name?: string;
  machanic?: CampaignMachanic;
  machanicName?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  startDateShow?: string;
  endDateShow?: string;
  limit?: number;
  amountGoal?: number;
  rewardDateShow?: string;
  diffAmount?: number;
  customerRank?: number;
  totalCoupon?: number;
  rewardName?: string;
  percent?: number;
  isReserved: boolean;
  remaining?: number;
  diffDate?: number;
  amount?: number;
  endedAnnouncement?: string;
  rewardedAnnouncement?: string;
  detailAnnouncement?: string;
  checkWinerLink?: string;
  isEnded: boolean;
  isRewarded: boolean;
  winRewardDetail?: WinRewardDetail;
  url?: string;
  receipts?: Receipt[];
}

export class MyCampaignConverter {
  public static fromJson(json: any): MyCampaign {
    const model: MyCampaign = {
      id: json['id'],
      campaignId: json['CAMPAIGN_ID'],
      name: json['name'],
      machanic: json['machanic'],
      machanicName: json['machanicname'],
      description: json['description'],
      startDate: json['start_date'],
      endDate: json['end_date'],
      startDateShow: json['start_date_show'],
      endDateShow: json['end_date_show'],
      limit: json['limit'] ? Number(json['limit']) : undefined,
      amountGoal: json['amount_goal']
        ? Number(json['amount_goal'].toString().trim().replace(/,/g, ''))
        : undefined,
      rewardDateShow: json['reward_date_show'],
      diffAmount: json['diff_amount']
        ? Number(json['diff_amount'].toString().trim().replace(/,/g, ''))
        : undefined,
      customerRank: json['custrank'] ? Number(json['custrank']) : undefined,
      totalCoupon: json['totalCoupon']
        ? Number(json['totalCoupon'])
        : undefined,
      rewardName: json['reward_name'],
      percent: json['percent'] ? Number(json['percent']) : undefined,
      isReserved: json['is_reserved'] === true ? true : false,
      remaining: json['remaining'] ? Number(json['remaining']) : undefined,
      diffDate: json['diff_date']
        ? Number(json['diff_date'].toString().trim().replace(/,/g, ''))
        : undefined,
      amount: json['AMOUNT']
        ? Number(json['AMOUNT'].toString().trim().replace(/,/g, ''))
        : undefined,
      endedAnnouncement: json['ended_announcement'],
      rewardedAnnouncement: json['rewarded_announcement'],
      detailAnnouncement: json['detail_announcement'],
      checkWinerLink: json['checkwinerlink'],
      isEnded: json['isEnd'] === true ? true : false,
      isRewarded: json['isReward'] === true ? true : false,
      winRewardDetail: json['winRewardDetail']
        ? WinRewardDetailConverter.fromJson(json['winRewardDetail'])
        : undefined,
      url: json['url'],
      receipts: json['receipts']
        ? json['receipts'].map((x: any) => ReceiptConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface OfflineMyCampaign {
  name?: string;
  slug?: string;
  isRecommended: boolean;
  image?: string;
  stores?: string[];
  steps?: OfflineCampaignStep[];
  currentStep?: number;
  currentPercent?: number;
  isLimit: boolean;
  currentAmount?: number;
  description?: string;
}

export class OfflineMyCampaignConverter {
  public static fromJson(json: any): OfflineMyCampaign {
    const model: OfflineMyCampaign = {
      name: json['name'],
      slug: json['slug'],
      isRecommended: json['isrecommended'] === true ? true : false,
      image: json['image'],
      stores: json['stores'] ? json['stores'] : undefined,
      steps: json['step']
        ? json['step'].map((x: any) => OfflineCampaignStepConverter.fromJson(x))
        : undefined,
      currentStep: json['currentStep']
        ? Number(json['currentStep'])
        : undefined,
      currentPercent: json['currentPercent']
        ? Number(json['currentPercent'])
        : undefined,
      isLimit: json['isLimit'] === true ? true : false,
      currentAmount: json['currentAmount']
        ? Number(json['currentAmount'])
        : undefined,
      description: json['description'],
    };
    return modelValue(model);
  }
}

export interface OfflineCampaignStep {
  minAmount?: number;
  maxAmount?: number;
  rewardValue?: number;
  rewardQnt?: number;
  rewardText?: string;
}

export class OfflineCampaignStepConverter {
  public static fromJson(json: any): OfflineCampaignStep {
    const model: OfflineCampaignStep = {
      minAmount: json['minAmount'] ? Number(json['minAmount']) : undefined,
      maxAmount: json['maxAmount'] ? Number(json['maxAmount']) : undefined,
      rewardValue: json['rewardValue']
        ? Number(json['rewardValue'])
        : undefined,
      rewardQnt: json['rewardQnt'] ? Number(json['rewardQnt']) : undefined,
      rewardText: json['rewardText'],
    };
    return modelValue(model);
  }
}

export interface CampaignDetailPageResponse {
  title?: string;
  sub?: string;
  //offline
  image?: string;
  name?: string;
  stores?: string[];
  currentAmount?: number;
  currentStep?: number;
  currentPercent?: number;
  steps?: OfflineCampaignStep[];
  receipts?: Receipt[];
  claimHistories?: ClaimHistory[];
  condition?: string;
  //online
  masterCampaignResult?: MyCampaign;
  remaining?: number;
  diffAmount?: number;
  customerRank?: number;
  percent?: number;
  isReserved?: boolean;
  myRankObject?: MyRankObject;
  scampaignResults?: ScampaignResult[];
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  isOnline?: boolean;
  shortDescription?: string;
  description?: string;
  cpngLimitType?: string;
  cpngLimitTypeName?: string;
  cpngLimitValue?: number;
  cpngLimitRedeem?: number;
  cpngRemainingValue?: number;
  termsCondition?: string;
}

export class CampaignDetailPageResponseConverter {
  public static fromJson(json: any): CampaignDetailPageResponse {
    const model: CampaignDetailPageResponse = {
      title: json['title'],
      sub: json['sub'],
      //offline
      image: json['image'],
      name: json['name'],
      stores: json['stores'] ? json['stores'] : undefined,
      currentAmount: json['currentAmount']
        ? Number(json['currentAmount'])
        : undefined,
      currentStep: json['currentStep']
        ? Number(json['currentStep'])
        : undefined,
      currentPercent: json['currentPercent']
        ? Number(json['currentPercent'])
        : undefined,
      steps: json['step']
        ? json['step'].map((x: any) => OfflineCampaignStepConverter.fromJson(x))
        : undefined,
      receipts: json['receipts']
        ? json['receipts'].map((x: any) => ReceiptConverter.fromJson(x))
        : undefined,
      claimHistories: json['historyClaim']
        ? json['historyClaim'].map((x: any) =>
            ClaimHistoryConverter.fromJson(x),
          )
        : undefined,
      condition: json['condition'],
      //online
      masterCampaignResult: json['mastercampaignresult']
        ? MyCampaignConverter.fromJson(json['mastercampaignresult'])
        : undefined,
      remaining: json['remaining'] ? Number(json['remaining']) : undefined,
      diffAmount: json['diff_amount']
        ? Number(json['diff_amount'].toString().trim().replace(/,/g, ''))
        : undefined,
      customerRank: json['custrank'] ? Number(json['custrank']) : undefined,
      percent: json['percent'] ? Number(json['percent']) : undefined,
      isReserved: json['is_reserved'] === true ? true : false,
      myRankObject: json['myrankobj']
        ? MyRankObjectConverter.fromJson(json['myrankobj'])
        : undefined,
      scampaignResults: json['scampaigsnumresults']
        ? json['scampaigsnumresults'].map((x: any) =>
            ScampaignResultConverter.fromJson(x),
          )
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      isOnline: json['mastercampaignresult'] !== undefined ? true : false,
      shortDescription: json['shortDescription'],
      description: json['description'],
      cpngLimitType: json['cpngLimitType'],
      cpngLimitTypeName: json['cpngLimitTypeName'],
      cpngLimitValue: json['cpngLimitValue']
        ? Number(json['cpngLimitValue'])
        : undefined,
      cpngLimitRedeem: json['cpngLimitRedeem']
        ? Number(json['cpngLimitRedeem'])
        : undefined,
      cpngRemainingValue: json['cpngRemainingValue']
        ? Number(json['cpngRemainingValue'])
        : undefined,
      termsCondition: json['terms_condition'],
    };
    return modelValue(model);
  }
}

export interface MyRankObject {
  id?: number;
  campaignId?: string;
  amount?: number;
  diffAmount?: number;
  customerRank?: number;
}

export class MyRankObjectConverter {
  public static fromJson(json: any): MyRankObject {
    const model: MyRankObject = {
      id: json['id'],
      campaignId: json['CAMPAIGN_ID'],
      amount: json['AMOUNT']
        ? Number(json['AMOUNT'].toString().trim().replace(/,/g, ''))
        : undefined,
      diffAmount: json['diff_amount']
        ? Number(json['diff_amount'].toString().trim().replace(/,/g, ''))
        : undefined,
      customerRank: json['custrank'] ? Number(json['custrank']) : undefined,
    };
    return modelValue(model);
  }
}

export interface ScampaignResult {
  name?: string;
  amountShow?: string;
  isMe: boolean;
  customerRank?: number;
}

export class ScampaignResultConverter {
  public static fromJson(json: any): ScampaignResult {
    const model: ScampaignResult = {
      name: json['cname'],
      amountShow: json['AMOUNTSHOW'],
      isMe: json['isme'] === true ? true : false,
      customerRank: json['custrank'] ? Number(json['custrank']) : undefined,
    };
    return modelValue(model);
  }
}

export interface WinRewardDetail {
  rewardName?: string;
  rewardList?: string[];
  lastContactDate?: string;
}

export class WinRewardDetailConverter {
  public static fromJson(json: any): WinRewardDetail {
    const model: WinRewardDetail = {
      rewardName: json['rewardName'],
      rewardList: json['rewardList'],
      lastContactDate: json['lastContactDate'],
    };
    return modelValue(model);
  }
}

export interface Receipt {
  isCancel: boolean;
  isUsed: boolean;
  isOnline: boolean;
  orderNumber?: string;
  storeName?: string;
  dateTimeShow?: string;
  collectAmount?: number;
  orderAmount?: number;
  posRefNumber?: string;
}

export class ReceiptConverter {
  public static fromJson(json: any): Receipt {
    const model: Receipt = {
      isCancel: json['isCancel'] === true ? true : false,
      isUsed: json['isUsed'] === true ? true : false,
      isOnline: json['isOnline'] === true ? true : false,
      orderNumber: json['orderNumber'] || json['ref'],
      storeName: json['storeName'] || json['stname'],
      dateTimeShow: json['datetime'],
      collectAmount: json['collectAmount']
        ? Number(json['collectAmount'].toString().trim().replaceAll(',', ''))
        : undefined,
      orderAmount:
        json['orderAmount'] || json['order_amount']
          ? Number(
              (json['orderAmount'] ?? json['order_amount'])
                .toString()
                .trim()
                .replaceAll(',', ''),
            )
          : undefined,
      posRefNumber: json['order_number'],
    };
    return modelValue(model);
  }
}

export interface ClaimHistory {
  dateTimeShow?: string;
  rewardValue?: number;
  rewardQuantity?: number;
  sumCollectAmount?: number;
  details?: Receipt[];
  rewards?: ClaimedReward[];
}

export class ClaimHistoryConverter {
  public static fromJson(json: any): ClaimHistory {
    const model: ClaimHistory = {
      dateTimeShow: json['date'],
      rewardValue: json['rewardValue']
        ? Number(json['rewardValue'].toString().trim().replaceAll(',', ''))
        : undefined,
      rewardQuantity: json['rewardQnt'] ? Number(json['rewardQnt']) : undefined,
      sumCollectAmount: json['sumcollectAmount']
        ? Number(json['sumcollectAmount'].toString().trim().replaceAll(',', ''))
        : undefined,
      details: json['detail']
        ? json['detail'].map((x: any) => ReceiptConverter.fromJson(x))
        : undefined,
      rewards: json['rewards']
        ? json['rewards'].map((x: any) => ClaimedRewardConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface ClaimedReward {
  value: number;
  qnt: number;
}

export class ClaimedRewardConverter {
  public static fromJson(json: any): ClaimedReward {
    const model: ClaimedReward = {
      value: Number(json['value'] ?? 0),
      qnt: Number(json['qnt'] ?? 0),
    };
    return modelValue(model);
  }
}
