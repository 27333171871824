import {
  BillDataConverter,
  CallPaymentRequest,
  CallPaymentResponse,
  CancelRedeemResponse,
  CancelRedeemResponseConverter,
  CardSNameResponse,
  CardSNameResponseConverter,
  InitiateRedeemResponse,
  InitiateRedeemResponseConverter,
  InstallmentPlan,
  InstallmentPlanConverter,
  NextPayResponse,
  NextPayResponseConverter,
  PaymentMethod,
  PreProcResponse,
  PreSumConverter,
  QRCodeDataConverter,
  SamedayChangeResponse,
  SamedayChangeResponseConverter,
  ServiceSlotRequest,
  ServiceSlotResponse,
  ServiceSlotResponseConverter,
} from '@/models/Checkout.model';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { detectBrower } from '@/utils/format';
import axios from 'axios';
import { ProductModel, ProductModelConverter } from '@/models/Product.model';
import { CheckStockSkuResponse } from '@/models/Stock.model';
import { ShipmentType } from '../server/shipmentPageService';
import { t1Timeout } from '@/constants/defaultValue.constant';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';

export const callVerifyRedeem = async ({
  lang,
  ref,
  pin,
  requestID,
  paymentMethod,
  id,
  burnType,
}: {
  lang?: string;
  ref?: string;
  pin: string;
  requestID: string;
  paymentMethod?: PaymentMethod;
  id?: number;
  burnType: string;
}): Promise<{
  data: CancelRedeemResponse | undefined | null;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/redeemverify_new_v2',
    lang,
    headers: {
      ref,
    },
    body: {
      verifyval: pin,
      requestID: requestID,
      id,
      burnType,
    },
    timeout: t1Timeout,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: CancelRedeemResponse | undefined | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = CancelRedeemResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);

  if (
    response.data &&
    response.status === 200 &&
    response.data?.isError !== true &&
    paymentMethod === PaymentMethod.t1cp
  ) {
    const payTheoneData = await callToPayTheonePoint({
      ref,
      lang,
    });
    if (payTheoneData.data && payTheoneData.status === 200) {
      return {
        data: {
          isError: false,
          message: payTheoneData.data.messageKey,
        },
        status: 200,
      };
    } else {
      return {
        data: {
          isError: true,
          message: payTheoneData.data.messageKey,
        },
        status: payTheoneData.status,
      };
    }
  }
  return response;
};

export const callCancelRedeem = async ({
  lang,
  ref,
}: {
  lang?: string;
  ref?: string;
}): Promise<{
  data: CancelRedeemResponse | undefined | null;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/cancelredeem_new_v2',
    lang,
    headers: {
      ref,
    },
    timeout: t1Timeout,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: CancelRedeemResponse | undefined | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = CancelRedeemResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const callInitiateRedeem = async ({
  point,
  stcode,
  ref,
  lang,
}: {
  point: number;
  stcode?: string;
  ref?: string;
  lang?: string;
}): Promise<{
  data: InitiateRedeemResponse | undefined | null;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/initiateredeem_new',
    lang,
    body: {
      point,
    },
    headers: {
      stcode,
      ref,
    },
    timeout: t1Timeout,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: InitiateRedeemResponse | undefined | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = InitiateRedeemResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const callInitiateRedeemv2 = async ({
  point,
  stcode,
  ref,
  lang,
  id,
  burnType,
}: {
  point: number;
  stcode?: string;
  ref?: string;
  lang?: string;
  id: number;
  burnType: string;
}): Promise<{
  data: InitiateRedeemResponse | undefined | null;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/initiateredeem_new_v2',
    lang,
    body: {
      point,
      id,
      burnType,
    },
    headers: {
      stcode,
      ref,
    },
    timeout: t1Timeout,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: InitiateRedeemResponse | undefined | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = InitiateRedeemResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getPreProc = async ({
  cardNumber,
  cardSName,
  bankSName,
  paymentName,
  paymentMethod,
  oldInstallmentPlans,
  inst,
  plan,
  ref,
  type,
}: {
  cardNumber: string;
  cardSName?: string;
  bankSName?: string;
  paymentName?: string;
  paymentMethod: PaymentMethod;
  oldInstallmentPlans?: InstallmentPlan[];
  inst?: number;
  plan?: number;
  ref?: string;
  type?: ShipmentType;
}): Promise<{
  data: PreProcResponse | undefined;
  status: number | null;
}> => {
  let cardInfo: CardSNameResponse | undefined;
  let instUse: number | undefined = inst;
  let planUse: number | undefined = plan;
  const isCallCard =
    paymentMethod === PaymentMethod.credit
      ? cardSName === undefined
      : paymentMethod === PaymentMethod.instllment
      ? bankSName === undefined
      : false;
  if (isCallCard) {
    const getCardData = await postDataFromAPI({
      path:
        paymentMethod === PaymentMethod.instllment
          ? type === ShipmentType.paylater
            ? '/qtgetbanksname'
            : '/getbanksname'
          : type === ShipmentType.paylater
          ? '/qtgetcardsname'
          : '/getcardsname',
      headers: { ref },
      body: {
        cardnum: cardNumber,
      },
    });
    if (getCardData.status === 200 && getCardData.data) {
      const rescard = getCardData.data.rescard;
      if (rescard) {
        try {
          cardInfo = CardSNameResponseConverter.fromJson(rescard);
        } catch (e) {
          console.log(e);
        }
      }
    }
  } else {
    cardInfo = {
      cardSName: cardSName,
      bankSName: bankSName,
      paymentName: paymentName,
    };
  }
  let installmentPlans: InstallmentPlan[] | undefined;
  if (
    paymentMethod === PaymentMethod.instllment &&
    (instUse === undefined || planUse === undefined)
  ) {
    const getInstPlanData = await postDataFromAPI({
      path: type === ShipmentType.paylater ? '/qtgetinstplan' : '/getinstplan',
      headers: { ref },
      body: {
        cardnum: cardNumber,
        banksname: cardInfo?.bankSName,
      },
    });
    if (getInstPlanData.status === 200 && getInstPlanData.data) {
      if (getInstPlanData.data.length > 0) {
        try {
          installmentPlans = getInstPlanData.data.map((e: any) =>
            InstallmentPlanConverter.fromJson(e),
          );
          if (installmentPlans && installmentPlans.length > 0) {
            instUse = installmentPlans[0].instRef;
            planUse = installmentPlans[0].idPlan;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  } else if (paymentMethod === PaymentMethod.instllment && instUse && planUse) {
    installmentPlans = oldInstallmentPlans;
  }
  const getData = await postDataFromAPI({
    path:
      paymentMethod === PaymentMethod.instllment
        ? type === ShipmentType.paylater
          ? '/qtgetpreinst'
          : '/getpreinst'
        : type === ShipmentType.paylater
        ? '/qtgetpreproc'
        : '/getpreproc',
    headers: { ref },
    body: {
      cardnum: cardNumber,
      cardname: cardInfo?.cardSName,
      banksname: cardInfo?.bankSName,
      inst: instUse,
      plan: planUse,
    },
  });
  if (getData.status === 200 && getData.data) {
    const presum = getData.data.result?.presum;
    const skucrcharge = getData.data.result?.skucrcharge;
    if (presum) {
      try {
        const preSum = PreSumConverter.fromJson(presum);
        const skuCrCharge = skucrcharge
          ? ProductModelConverter.fromJson({ ...skucrcharge, isCrCharge: true })
          : undefined;

        return {
          data: {
            preSum: preSum,
            skuCrCharge: skuCrCharge
              ? {
                  ...skuCrCharge,
                  base64: getData.data?.base64,
                  pimImage: getData.data?.pimImage,
                }
              : undefined,
            cardInfo,
            installmentPlans,
            currentPlan: installmentPlans?.find(
              (e) => e.idPlan === planUse && e.instRef === instUse,
            ),
          },
          status: getData.status,
        };
      } catch (error) {
        return { data: undefined, status: getData.status };
      }
    }
  }
  return { data: undefined, status: getData.status };
};

export const callKbVerify = async (
  ref: string,
): Promise<string | null | undefined> => {
  const getData = await axios.get(`/api/v2/KBANK/kbankverify/${ref}/${ref}`);
  return getData.data;
};

export const callBillPaymentVerify = async (
  ref: string,
): Promise<string | null | undefined> => {
  const getData = await axios.get(`/api/v2/getrefverify/${ref}/${ref}`);
  return getData.data;
};

export const callToPayTheonePoint = async ({
  lang,
  ref,
}: {
  lang?: string;
  ref?: string;
}): Promise<{
  data: {
    isError: boolean;
    messageKey?: string;
  };
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/paytheonepoint',
    lang,
    headers: { ref },
  });
  if (getData.status === 200 && getData.data) {
    if (getData.data.dbcode === true) {
      return {
        data: {
          isError: false,
        },
        status: getData.status,
      };
    } else {
      return {
        data: {
          isError: true,
          messageKey: getData.data.dbmessage,
        },
        status: getData.data.status,
      };
    }
  } else {
    return {
      data: {
        isError: true,
        messageKey: 'Error(PayTheOne).: ' + getData.status,
      },
      status: getData.status,
    };
  }
};

export const skuCheckStock = async ({
  lang,
  ref,
}: {
  lang?: string;
  ref?: string;
}): Promise<{
  data: CheckStockSkuResponse;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/skuoutofstock',
    lang,
    headers: ref ? { ref } : undefined,
  });
  //Return Products Data For Test Out of Stock
  // return {
  //   data: {
  //     isError: true,
  //     products: [
  //       {
  //         seq: 1,
  //         brand: 'BrandName',
  //         slugname: 'slugname',
  //         sku: 'ss',
  //         qty: 1,
  //         stock: 2,
  //         name: 'ProductName',
  //         amount: '100',
  //         base64: '',
  //         price: '100',
  //         isLineButton: false,
  //       },
  //     ],
  //   },
  //   status: getData.status,
  // };
  if (getData.status === 200 && getData.data) {
    if (getData.data) {
      const products: ProductModel[] = getData.data.map((x: any) =>
        ProductModelConverter.fromJson(x),
      );
      if (products.length > 0) {
        return {
          data: {
            isError: true,
            products,
          },
          status: getData.status,
        };
      } else {
        return {
          data: {
            isError: false,
          },
          status: getData.status,
        };
      }
    } else {
      return {
        data: {
          isError: false,
        },
        status: getData.status,
      };
    }
  } else {
    return {
      data: {
        isError: true,
        message: 'Connection Error (Stock): ' + getData.status,
      },
      status: getData.status,
    };
  }
};

export const callPayKasikorn = async ({
  type,
  ref,
  amount,
  lang,
}: {
  type?: ShipmentType;
  ref?: string;
  amount?: number;
  lang?: string;
}): Promise<{
  data: CallPaymentResponse;
  status: number | null;
}> => {
  const getTotalAmount = await getDataFromAPI({
    path:
      type === ShipmentType.repayment
        ? '/wgettotamt'
        : ShipmentType.paylater
        ? '/qtgettotamt'
        : '/gettotamt',
    headers: { ref },
  });
  let amt = amount;
  let glbranch = '';
  if (getTotalAmount.status === 200 && getTotalAmount.data) {
    if (getTotalAmount.data.dbitems.length > 0) {
      let item = getTotalAmount.data.dbitems[0];
      amt = Number(item.gtotal.toString().replaceAll(',', ''));
      glbranch = item.glbranch;
    }
  }
  const goToKbankOrder = await postDataFromAPI({
    path: type === ShipmentType.paylater ? '/qtgotokborder' : '/gotokborder',
    lang,
    headers: { ref },
    body: {
      amt,
      glbranch,
      isTimestamp: true,
    },
  });
  if (goToKbankOrder.status === 200 && goToKbankOrder.data) {
    const qrCodeData = await axios.get(
      `/api/v2/KBANK/kbankqr?para=${btoa(goToKbankOrder.data)}` +
        (type === ShipmentType.repayment
          ? `&isRePayment=Y`
          : type === ShipmentType.paylater
          ? `&isPayLater=Y`
          : ''),
    );
    if (qrCodeData.status === 200 && qrCodeData.data) {
      if (qrCodeData.data && qrCodeData.data.status == 1) {
        return {
          data: {
            isError: false,
            qrCodeData: QRCodeDataConverter.fromJson(qrCodeData.data),
            paymentMethod: PaymentMethod.qrcode,
          },
          status: qrCodeData.status,
        };
      } else {
        return {
          data: {
            isError: true,
            messageKey: qrCodeData.data.message,
            isRedirect: true,
          },
          status: qrCodeData.status,
        };
      }
    } else {
      return {
        data: {
          isError: true,
          messageKey: `QRError : ${goToKbankOrder.status}`,
          isRedirect: true,
        },
        status: goToKbankOrder.status,
      };
    }
  } else {
    return {
      data: {
        isError: true,
        messageKey: `KBError : ${goToKbankOrder.status}`,
        isRedirect: true,
      },
      status: goToKbankOrder.status,
    };
  }
};

export const callPrintBillPayment = async ({
  ref,
  lang,
}: {
  ref?: string;
  lang?: string;
}): Promise<{
  data: CallPaymentResponse;
  status: number | null;
}> => {
  const getBillData = await postDataFromAPI({
    path: '/billpayment/getbilldata',
    lang,
    headers: { ref },
  });

  if (getBillData.status === 200 && getBillData.data) {
    if (getBillData.data && getBillData.data.status == 1) {
      return {
        data: {
          isError: false,
          billData: BillDataConverter.fromJson(getBillData.data),
          isRedirect: false,
          paymentMethod: PaymentMethod.billpayment,
        },
        status: getBillData.status,
      };
    } else {
      return {
        data: {
          isError: true,
          messageKey: getBillData.data.message,
          isRedirect: true,
          paymentMethod: PaymentMethod.billpayment,
          errorCode: getBillData.data.errorCode,
        },
        status: getBillData.status,
      };
    }
  } else {
    return {
      data: {
        isError: true,
        messageKey: `BillError : ${getBillData.status}`,
        isRedirect: true,
        paymentMethod: PaymentMethod.billpayment,
      },
      status: getBillData.status,
    };
  }
};

export const callPayment = async ({
  paymentMethod,
  amount,
  deliveryInput,
  isRequestTax,
  receiveWith,
  receiveValue,
  markPan,
  markSName,
  inst,
  plan,
  deliveryType,
  dcsAvailable,
  slotSelected,
  ref,
  stcode,
  lang,
  type,
}: CallPaymentRequest): Promise<{
  data: CallPaymentResponse;
  status: number | null;
}> => {
  const checkBeforePay = await getDataFromAPI({
    path:
      type === ShipmentType.repayment
        ? '/wckbepay'
        : type === ShipmentType.paylater
        ? '/qtckbepay'
        : '/ckbepay',
    lang,
    headers: { ref, stcode },
  });
  if (checkBeforePay.status === 200 && checkBeforePay.data) {
    const errorMessage = checkBeforePay.data.dbitems[0].msg;
    const skuOutOfStock = await skuCheckStock({ lang, ref });
    if (skuOutOfStock.data.isError && type !== ShipmentType.paylater) {
      return skuOutOfStock;
    }
    if (errorMessage.substring(0, 4) === 'OKOK') {
      const startToPay = await postDataFromAPI({
        path:
          type === ShipmentType.repayment
            ? '/wstart2pay'
            : type === ShipmentType.paylater
            ? '/qtstart2pay'
            : '/start2pay',
        lang,
        headers: { ref, stcode },
        body: {
          payment: JSON.stringify({
            tendor: 'CALL ' + paymentMethod.toString(),
            amount: amount,
          }),
          deliver: deliveryInput,
          cksttax: isRequestTax === true ? 1 : 0,
          etaxtype: receiveWith,
          etaxval: receiveValue,
          paidby: paymentMethod.toString(),
          markpan: markPan ?? null,
          marksname: markSName ?? null,
          inst: inst,
          plan: plan,
        },
      });
      if (startToPay.status === 200 && startToPay.data) {
        if (startToPay.data.dbcode === true) {
          let reserveBooking: {
            data: any;
            status: number | null;
          } = { data: null, status: null };
          if (
            type !== ShipmentType.repayment &&
            type !== ShipmentType.paylater
          ) {
            reserveBooking = await postDataFromAPI({
              path: '/reservelongtailbooking',
              lang,
              headers: {
                ref,
                stcode,
                platform: 'WEB',
                device: detectBrower(),
                sbtran: Cookies.get(cookieKey.transactionId),
              },
              body: {
                slotsend: slotSelected,
                dcsAvailable: dcsAvailable === true ? 'Y' : 'N',
                dlvtype: deliveryType,
              },
              timeout: t1Timeout,
            });
          } else {
            reserveBooking = {
              data: {
                status: 1,
                redeemtheone: startToPay.data.redeemtheone,
              },
              status: 200,
            };
          }
          if (reserveBooking.status === 200 && reserveBooking.data) {
            switch (reserveBooking.data.status) {
              case 1:
                if (
                  reserveBooking.data.redeemtheone &&
                  reserveBooking.data.redeemtheone.isverify
                ) {
                  // paytheonepoint after verify pin
                  return {
                    data: {
                      ref,
                      isError: false,
                      the1RequestId:
                        reserveBooking.data.redeemtheone.redeeminfo?.requestID,
                      isVerifyPin: true,
                      paymentMethod,
                    },
                    status: 200,
                  };
                } else if (paymentMethod == PaymentMethod.t1cp) {
                  const payTheoneData = await callToPayTheonePoint({
                    ref,
                    lang,
                  });
                  if (payTheoneData.data && payTheoneData.status === 200) {
                    return {
                      data: {
                        isError: false,
                        paymentMethod,
                      },
                      status: 200,
                    };
                  } else {
                    return {
                      data: {
                        isError: true,
                        paymentMethod,
                      },
                      status: payTheoneData.status,
                    };
                  }
                } else if (paymentMethod == PaymentMethod.credit) {
                  return {
                    data: {
                      isError: false,
                      paymentMethod,
                    },
                    status: 200,
                  };
                } else if (paymentMethod == PaymentMethod.instllment) {
                  return {
                    data: {
                      isError: false,
                      paymentMethod,
                    },
                    status: 200,
                  };
                } else if (paymentMethod == PaymentMethod.qrcode) {
                  return {
                    data: {
                      isError: false,
                      paymentMethod,
                    },
                    status: 200,
                  };
                } else if (paymentMethod == PaymentMethod.billpayment) {
                  return {
                    data: {
                      isError: false,
                      paymentMethod,
                    },
                    status: 200,
                  };
                }
                break;
              case 2:
                return {
                  data: {
                    isError: true,
                    messageKey: 'thedeliverytimeyouse',
                  },
                  status: reserveBooking.status,
                };
              case 3:
                return {
                  data: {
                    isError: true,
                    messageKey: 'sorryyourorderisinth',
                  },
                  status: reserveBooking.status,
                };
              case 4:
                return {
                  data: {
                    isError: true,
                    messageKey: 'exchangethe1notsuccessful',
                  },
                  status: reserveBooking.status,
                };
            }
            return {
              data: {
                isError: true,
                messageKey: 'unabletoselectthisde',
              },
              status: reserveBooking.status,
            };
          } else {
            return {
              data: {
                isError: true,
                messageKey: 'unabletoselectthisde',
              },
              status: reserveBooking.status,
            };
          }
        } else if (startToPay.data.iserror) {
          return {
            data: {
              isError: true,
              messageKey: 'sorryyourorderisinth',
            },
            status: startToPay.status,
          };
        } else if (startToPay.data.dbmessage?.includes('Invalid Amount Ref')) {
          return {
            data: {
              isError: true,
              messageKey: startToPay.data.dbmessage,
            },
            status: startToPay.status,
          };
        } else if (startToPay.data.dbmessage?.includes('QR not available')) {
          return {
            data: {
              isError: true,
              messageKey: 'qrwillavailableinfuture',
            },
            status: startToPay.status,
          };
        } else if (
          startToPay.data.dbmessage?.includes('delevery_type_is_not_match')
        ) {
          return {
            data: {
              isError: true,
              messageKey: 'deleverytypeisnotmatch',
            },
            status: startToPay.status,
          };
        } else {
          return {
            data: {
              isError: true,
              messageKey: 'Error(S2P2)',
            },
            status: startToPay.status,
          };
        }
      } else {
        return {
          data: {
            isError: true,
            messageKey: 'Error(SPay): ' + startToPay.status,
          },
          status: startToPay.status,
        };
      }
    } else {
      return {
        data: {
          isError: true,
          messageKey: 'Error(Stock): ' + skuOutOfStock.status,
        },
        status: skuOutOfStock.status,
      };
    }
  } else {
    return {
      data: {
        isError: true,
        messageKey: 'Error(Check): ' + checkBeforePay.status,
      },
      status: checkBeforePay.status,
    };
  }
};

export const callNextPay = async ({
  ref,
}: {
  ref?: string;
}): Promise<{
  data: NextPayResponse | null;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    getDataFromAPI({
      path: '/nextpay',
      headers: { ref },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: NextPayResponse | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = NextPayResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const callDeleteCard = async ({
  cardUuid,
  lang,
}: {
  cardUuid: string;
  lang: string;
}): Promise<{
  data: { status: boolean; message?: string };
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/deletestorecard',
      lang,
      body: {
        cardUuid,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: { status: boolean; message?: string };
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = {
        status: data.status,
        message: data.message,
      };
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const samedayChange = async ({
  slotcode,
  isExpress,
  lang,
}: {
  slotcode: string;
  isExpress: boolean;
  lang?: string;
}): Promise<{
  data: SamedayChangeResponse | null;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/samedaychange',
      lang,
      body: {
        slotcode,
        is_check: isExpress,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: SamedayChangeResponse | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = SamedayChangeResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const bulkyChange = async ({
  slotcode,
  isBulky,
  lang,
}: {
  slotcode: string;
  isBulky: boolean;
  lang?: string;
}): Promise<{
  data: SamedayChangeResponse | null;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/bulkychange',
      lang,
      body: {
        slotcode,
        is_check: isBulky,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: SamedayChangeResponse | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = SamedayChangeResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getSlotService = async ({
  slotRequest,
  lang,
}: {
  slotRequest: ServiceSlotRequest;
  lang?: string;
}): Promise<{
  data: ServiceSlotResponse | null;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getslotservice',
      lang,
      body: slotRequest,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: ServiceSlotResponse | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = ServiceSlotResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const saveDemand = async ({
  slotcode,
  lang,
  products,
}: {
  slotcode: string;
  lang?: string;
  products?: string[];
}): Promise<boolean> => {
  await postDataFromAPI({
    path: '/savedemand',
    lang,
    headers: {
      'app-version': 'WEB',
    },
    body: {
      slotcode,
      products,
    },
  });
  return true;
};
