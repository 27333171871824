import {
  CampaignDetailPageResponse,
  CampaignDetailPageResponseConverter,
  CampaignPageResponse,
  CampaignPageResponseConverter,
  CampaignPageState,
} from '@/models/profile/Campaign.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromCMS, postDataFromAPI } from '@/utils/httpClient';

export const getProfileCampaign = async ({
  lang,
  state,
}: {
  lang?: string;
  state?: CampaignPageState;
}): Promise<{
  data: CampaignPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/campaign',
        lang,
        body: {
          state,
        },
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: {
      data: CampaignPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = CampaignPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

export const getProfileCampaignDetail = async ({
  lang,
  campaign,
  slug,
}: {
  lang?: string;
  campaign?: string;
  slug?: string;
}): Promise<{
  data: CampaignDetailPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: slug
          ? '/profile/offline-campaign-detail'
          : '/profile/campaign-detail',
        lang,
        body: {
          campaign,
          slug,
        },
      }),
    ]);

    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: {
      data: CampaignDetailPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = CampaignDetailPageResponseConverter.fromJson(data);
        const getDataStaticPage = await getDataFromCMS({
          path: campaign
            ? `/api/getstaticmenu/Campaign_condition_${campaign}`
            : `/api/getstaticmenu/Campaign_condition`,
          lang,
        });
        const dataStaticPage =
          getDataStaticPage.status == 200
            ? getDataStaticPage.data
              ? getDataStaticPage.data
              : undefined
            : undefined;
        const termsCondition =
          (dataStaticPage?.contents ?? []).length > 0
            ? dataStaticPage?.contents[0].detail ?? ''
            : '';
        response.data = {
          ...response.data,
          termsCondition: termsCondition,
        };
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};
